<template>

  <van-nav-bar left-arrow @click-left='this.$router.push("/")' :title="this.movie.Title + this.seriesTitle">
    <template #right>
      <van-icon name="search" size="18"/>
    </template>
  </van-nav-bar>

  <div id="dplayer">
    <!--旧版播放器-->
  </div>

  <div class="van-hairline--surround" style="margin-top: 20px;padding:  20px 20px 20px 20px " v-if="!this.movie.Type">
  <van-cel>
    <van-row gutter="5">
      <van-col span="12">
        <van-button block icon="arrow-left" :disabled="this.info.Serial == 1" @click="playPrev" type="success">上一集</van-button>
      </van-col>

      <van-col span="12">
        <van-button block icon="arrow" :disabled="this.info.Serial == this.series.length + 1"  @click="playNext"  icon-position="right" type="success">下一集</van-button>
      </van-col>
    </van-row>
  </van-cel>
  </div>
  <!--  替换新播放器-->
  <!--  <vue-plyr ref="plyr">-->
  <!--    <video controls crossorigin playsinline poster="" data-plyr-config='{ "title": "Movie" }'  ></video>-->
  <!--  </vue-plyr>-->

  <div class="van-hairline--surround" style="margin-top: 20px;padding:  20px 20px 20px 20px ">
    <div class="van-ellipsis">{{ this.movie.Title }}<span v-if="!this.movie.Type"> - 第{{ this.info.Serial }}集</span>
    </div>

    <div style="margin-top: 20px" v-if="!this.movie.Type">
      <van-row gutter="8">
        <van-col v-for="item in series" :key="item.ID" span="4" style="margin-top: 0.5rem">
          <van-button  block type="primary" :disabled="this.info.Serial === item.Serial" @click='getMovieInfo(item.ID)'>{{ item.Serial }}</van-button>
        </van-col>
      </van-row>
    </div>
  </div>

  <!--  <vue-plyr>-->
  <!--    <video controls crossorigin playsinline poster="https://bitdash-a.akamaihd.net/content/sintel/poster.png"></video>-->
  <!--  </vue-plyr>-->


</template>


<script>
import DPlayer from 'dplayer';
import {saveProgress, seriesInfo} from '@/http/play'
import {Notify, Toast} from 'vant'


export default {
  data() {
    return {
      first_play: true,
      series: [],
      seriesTitle: "",
      info: {},
      movie: {
        Type:1
      },
      movie_id: 0,
      player: {},
      hasShowFoot:false,
      prefix: "movie_play_progress",
      loadingVideo:false
    }
  },
  mounted() {
    this.movie_id = this.$router.currentRoute.value.params.mid
    // let id = this.getLastPlayId()
    this.getMovieInfo()
  },
  beforeUnmount() {
    this.player.destroy()
  },
  methods: {
    getMovieInfo(id = 0) {
      seriesInfo(this.movie_id, id).then(function (response) {
        this.loadingVideo = !this.loadingVideo
        Toast.loading({
          message: '加载中...',
          forbidClick: this.loadingVideo,
          loadingType: 'spinner',
        });

        let playUrl
        let quality = new Array()


        if (response.data.info.LocalLink && response.data.info.LocalLink != '') {
          playUrl = "https:" + response.data.info.LocalLink
          quality.push({
            name: '高清',
            url: playUrl,
            type: 'auto',
          })
        }
        if (response.data.info.SpiderLink != '') {
          quality.push({
            name: '标清',
            url: response.data.info.SpiderLink,
            type: 'auto',
          })
        }

        let resSeries = response.data.series
        this.info = response.data.info
        this.movie = response.data.movie
        let server_history = response.data.history//服务器播放时间
        this.series = resSeries

        //非电影表头不用加剧集
        if (this.movie.Type != 1){
          this.seriesTitle = ' 第' + this.info.Serial + '集'
        }
        console.warn(quality)
        let video = {
          quality: quality,
          defaultQuality: 0,
        }
        console.warn(video)
        this.player = new DPlayer({
          container: document.getElementById('dplayer'),
          video:video,
        });

        let playHistory  = this.getPlayProgress(server_history)
        //读取是否跳过片头
        if (playHistory == 0 && this.movie.head_time > 0) {
          playHistory = this.movie.head_time
        }
        if (playHistory > 0) {
          this.player.seek(playHistory)
        }

        //保持播放位置
        this.player.on('timeupdate', function () {
          //跳过片尾
          if (this.player.video.duration - this.player.video.currentTime <= this.movie.foot_time && !this.hasShowFoot){
            // alert('是否下一集')
            this.hasShowFoot = true
          }
          this.savePlayProgress(this.player.video.currentTime)
        }.bind(this))

        //自动播放下一集
        this.player.on('ended',function (){
          let nextId = this.getNextId()
          this.clearPlayProgress()
          this.getMovieInfo(nextId)
          //清理播放记录
          Notify({ type: 'success', message: '自动播放下一集' });
        }.bind(this))

        this.loadingVideo = !this.loadingVideo

      }.bind(this))
    },

    //保持播放进度
    savePlayProgress(time) {
      // let key = this.info.ID + this.prefix
      // let val = time
      //每次10秒保存数据到服务，防止服务器压力过大
      if (parseInt(time) % 3 === 0 && time > 30 ){
        let duration = this.player.video.duration * this.movie.Episodes
        saveProgress(this.movie_id, this.info.ID, parseInt(time),parseInt(duration))
      }
      // localStorage.setItem(key, val)
      // localStorage.setItem(this.movie_id + "_last_play", this.info.ID)
    },
    //获取最后播放的id
    getLastPlayId() {
      let id = localStorage.getItem(this.movie_id + "_last_play")
      return id ? id : 0
    },
    //清理播放记录
    clearPlayProgress(){
      this.savePlayProgress(0)//清理本地
      saveProgress(this.movie_id, this.info.ID, 0,0)//清理服务器
    },
    //获取之前的播放进度
    getPlayProgress(server_history) {
      // let key = this.info.ID + this.prefix
      // let playHistory = localStorage.getItem(key)
      // if (playHistory == null) {
      //   playHistory = 0
      // }
      let playHistory = 0
      //读取上次播放时间 读取服务器时间
      if (server_history.time > 0 && server_history.time > playHistory && (server_history.sid == this.info.ID || this.info.ID == 0)) {
        playHistory = server_history.time
      }

      return playHistory
    },
    //获取下一集
    getNextId() {
      for (let i = 0; i < this.series.length; i++){
        if (this.series[i].Serial == this.info.Serial + 1){
          return this.series[i].ID
        }
      }
    },
    //获取下一集
    getPrevId() {
      for (let i = 0; i < this.series.length; i++){
        if (this.series[i].Serial == this.info.Serial - 1){
          return this.series[i].ID
        }
      }
    },
    //播放上一集
    playPrev(){
      this.getMovieInfo(this.getPrevId())
    },
    //播放下一集
    playNext(){
      this.getMovieInfo(this.getNextId())
    },
  }
}

</script>